.progress-container {
    margin: 1rem 0;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 8px;
}

.progress-bar {
    height: 8px;
    background: #e9ecef;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 0.5rem;
}

.progress-fill {
    height: 100%;
    background: #233554;
    transition: width 0.3s ease;
}

.progress-text {
    font-size: 0.875rem;
    color: #666;
}

.file-input {
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 6px;
    width: 100%;
}

.file-name {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #666;
}

/* Reusing existing styles from add-product-form */
.add-product-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.loading-text {
    color: #666;
    padding: 0.5rem 0;
    font-style: italic;
}

.error-message {
    color: #dc3545;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
}

.json-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: monospace;
    font-size: 14px;
    resize: vertical;
}

.json-input:focus {
    border-color: var(--primary-color);
    outline: none;
}

/* File input styling */
.file-input-container {
    position: relative;
    display: inline-block;
}

.file-input-container input[type="file"] {
    display: none;
}

.file-input-button {
    display: inline-block;
    padding: 10px 20px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    border: none;
    width: auto;
}

.file-input-button:hover {
    background-color: #1a2844;
}

.file-input-button i {
    margin-right: 8px;
}

.file-name {
    margin-top: 8px;
    padding: 8px;
    background-color: #f8f9fa;
    border-radius: 4px;
}

/* Make all selectors more specific by prefixing with the parent container class */
.import-data-container .form-group,
.import-data-page .form-group {
    margin-bottom: 1.5rem;
    width: 100%;
}

.import-data-container .form-group label,
.import-data-page .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.import-data-container .form-group select,
.import-data-page .form-group select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
}

/* File input specific styles */
.import-data-container .import-file-input-container,
.import-data-page .import-file-input-container {
    position: relative;
    display: inline-block;
}

.import-data-container .import-file-input,
.import-data-page .import-file-input {
    display: none;
}

.import-data-container .import-file-button,
.import-data-page .import-file-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #233554;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    border: none;
    width: auto;
}

.import-data-container .import-file-button:hover,
.import-data-page .import-file-button:hover {
    background-color: #1a2844;
}

.import-data-container .import-file-button i,
.import-data-page .import-file-button i {
    margin-right: 8px;
}

.import-data-container .import-file-name,
.import-data-page .import-file-name {
    margin-top: 8px;
    padding: 8px;
    background-color: #f8f9fa;
    border-radius: 4px;
    font-size: 14px;
    color: #495057;
}

.import-data-container .import-file-name i,
.import-data-page .import-file-name i {
    margin-right: 8px;
    color: #233554;
}

.import-data-container h1,
.import-data-page h1 {
    font-size: 24px;
    color: var(--text-color);
}

.info-message {
    background-color: #fff3cd;
    color: #856404;
    border: 1px solid #ffeeba;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 4px;
    font-size: 14px;
}

/* Remove the checkbox-specific styles we added earlier */
.checkbox-group {
    margin-bottom: 20px;
}

/* Use the existing checkbox styles from products.css */
.checkbox-label {
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #ddd;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.checkbox-label input[type="checkbox"]:checked {
    background-color: var(--submit-button);
    border-color: var(--submit-button);
}

.checkbox-label input[type="checkbox"]:checked::after {
    content: '✓';
    color: white;
    font-size: 12px;
    font-weight: bold;
}

.checkbox-label input[type="checkbox"]:hover {
    border-color: var(--submit-button);
}

.checkbox-label input[type="checkbox"]:focus {
    outline: 2px solid rgba(0, 102, 204, 0.2);
    outline-offset: 2px;
}

.checkbox-help {
    margin-top: 5px;
    font-size: 0.85rem;
    color: #666;
    margin-left: 28px;
}